import {fetchGet} from '@/router/https'
//获取图书导航栏
export function getTabList(data){
    return fetchGet('/mallapp/juhe/goodbook/catalog',data);
}
// 查看图书详情
export function getDetail(data){
    return fetchGet("/mallapp/juhe/goodbook/query",data);
}
// 生日花语
export function getDirthday(data){
    return fetchGet("/mallapp/juhe/birthdayFlower/query",data);
}
