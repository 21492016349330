<template>
  <div id="app">
    <div class="header">
      <van-nav-bar
        title="图书电商"
        left-arrow
        fixed
        placeholder
        :border="false"
        @click-left="onClickLeft"
      />

      <van-tabs v-model="active">
        <van-tab
          v-for="(item, index) in title"
          :title="item.catalog"
          :key="index"
        >
        </van-tab>
      </van-tabs>
    </div>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        style="margin-top: 110px"
      >
        <ul class="list" v-for="(i, n) in list" :key="n">
          <div class="container">
            <div class="container-item" @click="enterDetail(i)">
              <img :src="i.img" alt="" style="width: 60px; height: 81px" />
              <div class="container-item-right">
                <p style="font-size: 12px; font-weight: bold">{{ i.title }}</p>
                <p>{{ i.sub1 }}</p>
                <p>{{ i.reading }}</p>
              </div>
            </div>
          </div>
        </ul>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getTabList, getDetail } from "@/api/birthdayLibrary";
export default {
  data() {
    return {
      active: 0,
      title: ["中国文学", "外国文学", "中国历史", "散文", "人物传记", "小说"],
      query: {
        catalogId: null,
        pn: 0,
        rn: 10,
        dtype: "json",
      },
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      begin: true,
      // 总条数
      total: null,
    };
  },
  watch: {
    active: {
      handler(value) {
        this.query.catalogId = this.title[value].id;
        this.query.pn = 1;
        this.list = [];
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
      // console.log('返回');
      // this.$router.go(-1)
    },
    enterDetail(val) {
      this.$router.push({
        path: "/libraryDetail",
        query: {
          obj: val,
        },
      });
    },
    // 获取导航栏
    getTab() {
      getTabList({
        dtype: "json",
      }).then((res) => {
        console.log(res.data.data.result, 2222);
        this.title = res.data.data.result;

        this.getList();
      });
    },
    getList() {
      this.query.catalogId = this.title[this.active].id;
      this.begin = false;
      console.log(999);
      getDetail(this.query).then((res) => {
        this.begin = true;
        this.total = res.data.data.result.totalNum;
        console.log(this.total);
        res.data.data.result.data.forEach((i) => {
          this.list.push(i);
        });
        if (this.list.length >= this.total) {
          this.finished = true;
        }
        this.loading = false;
      });
    },
    onLoad() {
      if (this.refreshing) {
        // that.list = [];
        this.refreshing = false;
      }

      this.query.pn += 1;
      this.getTab();
    },
    onRefresh() {
      const that = this;
      // 清空列表数据
      that.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      that.loading = true;
      that.onLoad();
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  background-color: #f1f2f6;
}

.header {
  position: fixed;
  width: 100%;
  height: 100px;
  min-height: 60px;
  line-height: 60px;
  z-index: 999;
}

/deep/ .van-nav-bar,
/deep/ .van-tabs__nav {
  background: #f1f2f6;
}

/deep/ .van-nav-bar__left {
  padding: 0 5px;
}

/deep/ .van-tabs__line {
  background-color: #0266f8;
}

.container {
  background: #f1f2f6;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 20px;
  flex: 1;

  &-item {
    display: flex;

    &-right {
      flex: 1;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    img {
      margin-right: 10px;
    }
  }
}
</style>
